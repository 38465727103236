<template>
  <div class="dewha-container w-100">
    <div
      class="d-flex align-items-center justify-content-center dewha-logo-section"
    >
      <div class="">
        <lazy-image
          class="dewha-logo"
          src="https://images.quizell.com/gallery/8611727080242.png"
        ></lazy-image>
      </div>
    </div>
    <div class="dewha-container1">
      <div class="dewha-card">
        <div class="dewha-progress-bar-section">
          <div class="dewha-progress-bar">
            <div
              class="dewha-active-bar"
              :style="{ width: (PageOrder/totalPagesLength)*100 + '%' }"
            ></div>
          </div>
          <div class="dewha-progressbar-counter">
            QUESTION {{ PageOrder }} OF {{ totalPagesLength }}
          </div>
        </div>
        <div class="">
          <!-- Question -->
          <div class="dewha-question-heading mt-4 mt-md-3 mb-3 mt-md-0">
            {{ getHeading[0].content }}
          </div>
          <!-- Options -->
          <div class="row dewha-options">
            <div
              class="col-12 col-md-6"
              v-for="(option,index) in getOptions"
              :key="option.id"
            >
              <div class="dewha-option">
                {{ option.value }}
                <label class="checkbox-container">
                  <input
                    type="radio"
                    v-model="selectedOption"
                    :value="option"
                    name="question-option"
                    @change="updateSelectedAnswer(option, index)"
                  />
                  <div class="checkmark"></div>
                  <svg
                    width="50"
                    height="50"
                    xmlns="http://www.w3.org/2000/svg"
                    class="celebrate"
                  >
                    <polygon points="0,0 10,10"></polygon>
                    <polygon points="0,25 10,25"></polygon>
                    <polygon points="0,50 10,40"></polygon>
                    <polygon points="50,0 40,10"></polygon>
                    <polygon points="50,25 40,25"></polygon>
                    <polygon points="50,50 40,40"></polygon>
                  </svg>
                </label>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        class="d-flex align-items-center justify-content-center gap flex-wrap justify-content-md-between my-5 pt-3 pb-5 py-md-5"
      >
      <div class="btn1">
          <button
           
            class="dewha-card-button d-flex align-items-center justify-content-center"
            @click="backPage"
          >
            
            <span  class="mr-2"
              ><i class="fa-solid fa-chevron-left"></i></span
            >Previous Question
          </button>
        </div>
        <div class="btn2">
          <button
            :disabled="Object.keys(selectedOption).length <= 0"
            :style="{ pointerEvents: isNextPageLoading != null ? 'none' : '' }"
            class="dewha-card-button d-flex align-items-center justify-content-center"
            @click="updateSelectedAnswer(selectedOption, 1)"
          >
           {{ isLastPage?'Show Results':'Next Question' }} 
            <div>
              <b-spinner
                style="color: var(--dewha-primary)"
                v-show="isNextPageLoading != null"
                small
              ></b-spinner>
            </div>
            <span v-show="isNextPageLoading == null" class="ml-2"
              ><i class="fa-solid fa-chevron-right"></i
            ></span>
          </button>
        </div>
       
      </div>
   
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";
import axios from "axios";
import { getFormatedUTCTimeStamp } from "../../../../composibles/getUTCTimeStamp";

export default {
  props: [
    "isTest",
    "isDesktopView",
    "animationType",
    "RoutePage",
    "getIsAnimationEnabled",
    "getAnimationSpeedinMillis",
    "apiToken",
  ],
  created() {
    this.pageDesign = this.getSinglePageValues();
  },
  data() {
    return {
      pageDesign: {},
      isNextPageLoading: null,
      selectedOption: {},
    };
  },
  computed: {
    ...mapGetters([
      "getIsLoadingQuestion",
      "getSelectedPage",
      "getUploadedImage",
      "getTotalPages",
      "getTotalPreviewPages",
      "getPreviewSettings",
      "getPreviewProgressBar",
      "getSavedLeadID",
      "translateText",
      "getAnimationSetting",
      "getFurnitureClientCounterData",
      "getTrackIkeaClientCounter",
      "getEnvConst",
      "getKozimaTimerSec",
      "getKozimCurrectSectionIndex",
      "getSelectedOptions"
    ]),
    isLastPage(){
      const singlePages =this.getTotalPreviewPages.filter(page=>page.value=='singleAnswer');
      const lastPage = singlePages.length-2;
      console.log(singlePages[lastPage].id,this.CurrentPage.id)
      return singlePages[lastPage].id==this.CurrentPage.id
    },
    getSelectedOption() {
      const optionObj = this.getSelectedOptions.questionAnswer.find(
        (option) => option.questionId == this.CurrentPage.id
      );
      return optionObj?.selectedOption;
    },

    getHeading() {
      return this.getSelectedPage.pageDesign.blocksArray.filter(
        (block) => block.type == "text"
      );
    },
    totalPagesLength() {
      const allowedValues = ["singleAnswer", "multipleAnswer"];

      return this.getTotalPreviewPages.reduce(
        (count, x) => (allowedValues.includes(x.value) ? count + 1 : count),
        0
      );
    },
    filteredQuestions() {
      const allowedValues = ["singleAnswer", "multipleAnswer"];
      return this.getTotalPreviewPages.filter((page) =>
        allowedValues.includes(page.value)
      );
    },
    PageOrder() {
      const index = this.filteredQuestions.findIndex(
        (page) => page.order === this.CurrentPage.order
      );
      if (index !== -1) {
        return index + 1;
      }
      return null;
    },
    getOptions() {
      return this.pageDesign.blocksArray[this.findOptionsIndex].options;
    },
    findOptionsIndex() {
      const index = this.pageDesign.blocksArray.findIndex(
        (block) => block.type == "option"
      );
      return index;
    },
    calcWidth() {
      const arr = this.filteredQuestions;
      let totalPagesLength = this.getTotalPreviewPages.length;
      let progressWidth = null;

      if (arr.length) {
        for (let i = 0; i < arr.length; i++) {
          if (
            this.getSelectedPage.value == arr[i].value &&
            this.getSelectedPage.order == arr[i].order
          ) {
            progressWidth = ((i + 1) / totalPagesLength) * 100;
          }
        }
      }

      return progressWidth;
    },
    isLoadingQuestion() {
      return this.getIsLoadingQuestion;
    },
    CurrentPage() {
      return this.getSelectedPage;
    },
  },
  methods: {
    ...mapActions([
      "goToNextPreviewPage",
      "goToBackPreviewPage",
      "updateSelectedOptionsObject",
      "createEndQuizPage",
      "kozimaCheckTimer",
    ]),
    ...mapMutations([
      "jumpToPreviewPage",
      "PreviewPagesStack",
      "backPageAnimation",
      "setIsLoadingQuestion",
    ]),
    backPage() {
      this.backPageAnimation();
      this.goToBackPreviewPage();
    },
    getSinglePageValues() {
      let getStartedDesignValues = {};
      if (this.getSelectedPage) {
        getStartedDesignValues = this.getSelectedPage.pageDesign;
      }
      return getStartedDesignValues;
    },

    async updateSelectedAnswer(value, selectedOptionIndex) {
      if (!value) return;

      if (this.isLoadingQuestion) return;
      const contentIndex = this.findOptionsIndex;
      await this.processSelectedOption(
        value,
        contentIndex,
        selectedOptionIndex
      );
    },
    async IncrementQuestionAnalyticsCount(
      selectedOptionObj,
      mode,
      selectedOptionIndex
    ) {
      if (this.isTest) {
        if (mode == "jumpLogic") return true;

        this.goToNextPreviewPage(this.getSelectedPage.value);
        return false;
      }

      let { quiz_analytic_id } = this.getSavedLeadID;

      if (quiz_analytic_id) {
        try {
          this.isNextPageLoading = selectedOptionIndex;
          this.setIsLoadingQuestion(true);
          let quizAnalyticsPayload = {
            api_token: this.apiToken || this.$route.params.id,
            question_id: this.CurrentPage.id,
            quiz_analytic_id: quiz_analytic_id,
            option_ids:
              selectedOptionObj.selectedOption &&
              "id" in selectedOptionObj.selectedOption
                ? [selectedOptionObj.selectedOption.id]
                : [],
          };

          const response = await axios.post(
            `/api/question/analytic/add`,
            quizAnalyticsPayload
          );

          if (response.status == 200) {
            if (mode == "jumpLogic") {
              return true;
            }

            this.goToNextPreviewPage(this.getSelectedPage.value);
            return false;
          }
        } catch (error) {
          if (error) {
            this.$toasted.show("Error occured ", {
              theme: "toasted-primary",
              type: "error",
              position: "bottom-center",
              duration: 2000,
            });
          }
        } finally {
          this.setIsLoadingQuestion(false);
          this.isNextPageLoading = null;
        }
      } else {
        try {
          this.isNextPageLoading = true;
          let data = {
            api_token: this.apiToken || this.$route.params.id,
            quizId: this.getPreviewSettings.quizId,
            value_type: "Started",
            started_at: getFormatedUTCTimeStamp(),
          };

          const response = await axios.post(`/api/updateQuizAnalytic`, data);
          if (response.status == 200) {
            let { quiz_analytic_id } = response.data.data;
            if (quiz_analytic_id) {
              await this.$store.commit(
                "assignQuizAnaylticsId",
                quiz_analytic_id
              );
              this.IncrementQuestionAnalyticsCount(
                selectedOptionObj,
                mode,
                selectedOptionIndex
              );
            }
          }
        } catch (error) {
          if (error) {
            this.$toasted.show("Error occured ", {
              theme: "toasted-primary",
              type: "error",
              position: "bottom-center",
              duration: 2000,
            });
          }
        } finally {
          this.isNextPageLoading = false;
        }

        // Recursive call
      }
    },

    async processSelectedOption(value, contentIndex, selectedOptionIndex) {
      try {
        const selectedOptionObj = this.createSelectedOptionObj(value);
        this.pageDesign.blocksArray[contentIndex].selectedOption = value;
        await this.updateSelectedOptionsObject(selectedOptionObj);

        this.IncrementQuestionAnalyticsCount(
          selectedOptionObj,
          "normal",
          selectedOptionIndex
        );
      } catch (error) {
        if (error) throw error;
      }
    },
    createSelectedOptionObj(value) {
      const obj = new Object();
      obj.questionId = this.getSelectedPage.id;
      obj.isMasterQuestion = this.getSelectedPage.isMasterQuestion;
      obj.questionType = this.getSelectedPage.value;
      obj.selectedOption = value;
      return obj;
    },
  },
  mounted(){
    this.selectedOption=this.getSelectedOption||{}
  }
};
</script>

<style>
@import url("https://fonts.googleapis.com/css2?family=Work+Sans:ital,wght@0,100..900;1,100..900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Amiko:wght@400;600;700&display=swap");
.dewha-logo-section {
  box-shadow: 0px 4px 6.8px rgba(0, 0, 0, 0.25);
  background: white;
  border-radius: 100px;
  margin: 0px 20px 0px 20px;
}
.dewha-container {
  background-image: url(https://images.quizell.com/gallery/2951727157199.png);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  margin: 0;
  height: 100vh;
}
.dewha-container1 {
  padding: 0px 20px 0px 20px;
  --dewha-primary: #f14257;
  --dewha-secondary: #393939;
}
.dewha-logo {
  /* width: 200px; */
  height: 50px;
  margin-top: 17px;
  margin-bottom: 17px;
}
.dewha-card {
  margin-top: 30px;
  border-radius: 70px;
  background-image: url(https://images.quizell.com/gallery/2221727182465.png);
  background-position: center;

  background-repeat: no-repeat;
  box-shadow: 10px 30px 50px rgba(0, 0, 0, 0.3);
  padding: 21px;
  position: relative;
}
.dewha-card::before {
  content: "";
  position: absolute;
  top: 20px;
  left: 0;
  right: 0;
  bottom: -16px;
  background-image: url(https://images.quizell.com/gallery/2221727182465.png);
  background-position: center;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  border-radius: 70px;
  z-index: -1;
  box-shadow: 0px 30px 50px rgba(0, 0, 0, 0.3);
}
.dewha-card::after {
  content: "";
  position: absolute;
  top: 20px;
  left: 0;
  right: 0;
  bottom: -31px;
  background-image: url(https://images.quizell.com/gallery/2221727182465.png);
  background-position: center;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  border-radius: 70px;
  z-index: -2;
}
.dewha-progress-bar {
  border-radius: 75px;
  background-color: #fff;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  width: 100%;
}
.dewha-active-bar {
  border-radius: 75px;
  background-color: var(--dewha-primary);
  height: 11px;
}
.dewha-progressbar-counter {
  color: var(--dewha-secondary);
  font-family: "Work Sans";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-top: 10px;
}
.dewha-question-heading {
  color: #393939;
  text-align: center;
  font-family: "Work Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 117.218%; /* 32.821px */
  letter-spacing: 1.4px;
}
.dewha-option {
  border-radius: 162px;
  background: #fff;
  color: #393939;
  font-family: "Work Sans";
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 117.218%; /* 23.444px */
  letter-spacing: 1px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 14px 19px;
}
.dewha-options {
  gap: 30px;
}
.dewha-card-button:disabled {
  background-color: #fc8291;
}
.dewha-card-button {
  border-radius: 81.943px;
  background: var(--dewha-primary);
  color: #fff;
  text-align: center;
  font-family: "Amiko";
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.35px;
  border: none;
  outline: none;
  padding: 16px 21px;
}
.dewha-card-button span {
  padding: 11px;
  background: #fff;
  border-radius: 50%;
  color: var(--dewha-primary);
  font-size: 12px;
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.btn1{
  order:2;
}
.btn2{
order:1;
}
@media (min-width: 1024px) {
  .dewha-started {
    font-size: 80px;
    text-align: left;
  }
  .dewha-card {
    padding: 46px 32px;
  }
  .dewha-container1 {
    padding: 0px 220px 0px 220px;
  }
  .dewha-card-button {
    font-size: 28px;
  }
  .dewha-card-image {
    width: 480px;
  }
}
@media (min-width: 768px) {
  .btn1{
  order:1;
}
.btn2{
order:2;
}
  .dewha-logo {
    width: 138px;
    height: 60px;
    margin-top: 17px;
    margin-bottom: 17px;
  }
  .dewha-logo-section {
    border-radius: 0px;
    width: 100%;
    margin: 0px;
  }
  .dewha-active-bar {
    height: 19px;
  }
  .dewha-progressbar-counter {
    font-size: 18px;
  }
  .dewha-card {
    background-size: 107%;
  }
  .dewha-question-heading {
    font-size: 28px;
  }
  .dewha-options {
    gap: 0px;
  }
  .dewha-options .col-md-6 {
    margin-top: 10px;
  }
  .dewha-option {
    padding: 21px 19px;
    font-size: 20px;
  }
  .dewha-card::after {
    background-size: 100%;
  }
  .dewha-card-button span {
    font-size: 20px;
    width: 30px;
    height: 30px;
  }
}
@media (min-width: 1200px) {
  .dewha-card {
    padding: 46px 32px;
  }
}

/* Hide the default checkbox */
.checkbox-container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

.checkbox-container {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  cursor: pointer;
  font-size: 20px;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  border-radius: 50%;
  border: 5px solid #e95087;
  margin: 0px;
}

/* Create a custom checkbox */
.checkmark {
  position: relative;
  top: 0;
  left: 0;
  height: 1.3em;
  width: 1.3em;
  transition: 0.3s;
  transform: scale(0);
  border-radius: 50%;
}

/* When the checkbox is checked, add a blue background */
.checkbox-container input:checked ~ .checkmark {
  background-color: #e95087;
  transform: scale(1.2);
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.checkbox-container input:checked ~ .checkmark:after {
  display: block;
}

.checkbox-container input:checked ~ .celebrate {
  display: block;
}

/* Style the checkmark/indicator */
.checkbox-container .checkmark:after {
  left: 0.45em;
  top: 0.25em;
  width: 0.3em;
  height: 0.5em;
  border: solid white;
  border-width: 0 0.15em 0.15em 0;
  transform: rotate(45deg);
}

.checkbox-container .celebrate {
  position: absolute;
  transform-origin: center;
  animation: kfr-celebrate 0.4s;
  animation-fill-mode: forwards;
  display: none;
  stroke: #e95087;
}

@keyframes kfr-celebrate {
  0% {
    transform: scale(0);
  }

  50% {
    opacity: 1;
  }

  100% {
    transform: scale(1.2);
    opacity: 0;
    display: none;
  }
}
</style>
